import { qs, qsa } from './dom'

export const fromHubspot = () => {
  for (const item of qsa(".active")) {
    item.classList.remove('active')
  }
  qs('.two-step-form-survey').classList.add('active');
  qs('#input_1_6').value = qs('.hs-firstname input').value;
  qs('#input_1_7').value = qs('.hs-lastname input').value;
  qs('#input_1_8').value = qs('.hs-email input').value;
  qs('.header').classList.add('hidden');
}
