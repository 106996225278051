import header from "./modules/header"
import "./modules/slider"
import { checkCookie } from "./utils/gate"
import { fromHubspot } from "./utils/free-survey"

window.addEventListener('load', () => {
	header()
	checkCookie()
	//PUM.getPopup(4348)// eslint-disable-line
});

window.addEventListener('resize', () => {
});

window.addEventListener('message', (event) => {
	if(document.querySelector('body').classList.contains('popup-free-trial') && document.querySelector('body').classList.contains('home')) {
		if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
			fromHubspot()
		}
	}
});
